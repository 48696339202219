import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "windows-folder"
    }}>{`Windows Folder`}</h1>
    <p>{`Dragnet allows you to create security tokens as Windows Folders.  With a Windows Folder based token, the act of opening the folder triggers the alert.  This is accomplished without installing any additional software.`}</p>
    <p>{`To create a Windows Folder based token, navigate to the `}<inlineCode parentName="p">{`New Token`}</inlineCode>{` interface and select `}<inlineCode parentName="p">{`Windows Folder`}</inlineCode>{` from the top drop down menu and complete the rest of the form as necessary. Then navigate to the token details for your Windows Folder token, `}<inlineCode parentName="p">{`Activate`}</inlineCode>{` and `}<inlineCode parentName="p">{`Download`}</inlineCode>{` the token.`}</p>
    <p>{`Your token will then be downloaded within a zip file. You will need to extract the folders inside the zip file to deploy the token. Right click (Do not double click) on the file, and select `}<inlineCode parentName="p">{`Extract all`}</inlineCode>{`.  Note that some third party extraction tools, such as 7zip, may corrupt the token when extracted.  We recommend using the default Windows extraction utility.  `}</p>
    <p>{`When you extract the contents of the zip file, you will notice one folder inside another.  This is because these tokens can fire alerts as soon as the folder is visible to the user. The folder does not necessarily need to be opened to be triggered.  However, it is more reliably triggered by opening the folder.  Once extracted, you should see folders as follows `}<inlineCode parentName="p">{`accounts-and-passwords/My Documents`}</inlineCode>{`.  You are free to rename these folders as appropriate given the context in which they are being deployed.`}</p>
    <p>{`Alerts originating from Windows Folders capture the Windows User Id and the Active Directory network domain from which the token was triggered.  This is extremely valuable because it will let you know which user account has been compromised in the event of an attack.  For example, if you placed a  `}<inlineCode parentName="p">{`Windows Folder`}</inlineCode>{` token on the desktop of a server and an attacker compromised the server and opened the folder, the alert would indicate which account was used to access the server. With this information, IT staff can immediately deactivate the account, immediately ending the attack.`}</p>
    <p><inlineCode parentName="p">{`Windows Folder`}</inlineCode>{` tokens leverage the DNS network to send notifications. When a user access a folder, Windows may send multiple DNS queries, some of which contain the User Id and network domain, some of which do not. Additionally, Windows may send MANY requests after a single access event. The Dragnet service automatically rate limits the events so you get no more than one event per minute. If the act of accessing the `}<inlineCode parentName="p">{`Windows Folder`}</inlineCode>{` token produces a DNS query with the User ID/network domain embedded, and another without that information, you will receive two alerts. Although we rate limit truly duplicate event data, we err on the side of notification if there are differences in the underlying event data. When you create a Windows Folder token, you have the option to suppress alerts that lack the user's username, computername, and network domain. This will reduce noise from an event, but could suppress an alert that is generated by a compromised system account.`}</p>
    <h3 {...{
      "id": "limitations"
    }}>{`Limitations`}</h3>
    <ol>
      <li parentName="ol">{`Windows Folder and DNS tokens can take a bit more time than HTTP tokens to trigger on our server.  Most of the time it will not take more than 90 seconds to trigger the event and send notifications.`}</li>
      <li parentName="ol">{`Windows folder tokens use the DNS system to work. As a result, for them to work, usernames must ONLY have characters used in the dns system.  Accordingly, usernames with characters allowed by Windows, but not by the domain system, will prevent these tokens from working.  Those characters include `}<inlineCode parentName="li">{`'_ ! # ^ ~`}</inlineCode></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      